<template>
  <section id="section5">
    <div class="s5_container">
      <div class="best_item">
        <p class="title">BEST! SELLER</p>
        <p class="sub_text">베스트 셀러를 만나보세요</p>
        <div class="best_menu">
          <ul>
            <li @click="category = b.name"
                v-for="b in BESTMENU" :key="b.id"
                :class="{active:category==b.name}"
              >
              {{b.name}}
            </li>
          </ul>
        </div>
        <component :is="category"></component>
      </div>
    </div>
  </section>
</template>

<script>
  const BESTMENU  = [
    {
      id:'1',
      name:'Necklace'
    },{
      id:'2',
      name:'Earring'
    },{
      id:'3',
      name:'Ring'
    },{
      id:'4',
      name:'Bracelet'
    }
  ]
  import Necklace from '../../components/bestSeller/Necklace'
  import Earring from '../../components/bestSeller/Earring'
  import Ring from '../../components/bestSeller/Ring'
  import Bracelet from '../../components/bestSeller/Bracelet'

  export default {
    name: "section5",
    components:{
      Necklace,Earring,Ring,Bracelet
    },
    data(){
      return{
        BESTMENU,
        category:'Necklace'
      }
    }
  }
</script>
