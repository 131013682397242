<template>
  <div id="Necklace" class="best_items">
    <div class="item_box">
      <div class="item" v-for="i in ITEM" :key="i.id">
        <img :src="i.img">
        <p class="name">{{i.name}}</p>
        <p class="price">{{i.price}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  const ITEM = [
    {
      id:'1',
      name:'14k 골드 반지',
      price:'1,200,000원',
      img:require('../../assets/images/item.png')
    },
    {
      id:'2',
      name:'14k 골드 반지',
      price:'1,200,000원',
      img:require('../../assets/images/item.png')
    },
    {
      id:'3',
      name:'14k 골드 반지',
      price:'1,200,000원',
      img:require('../../assets/images/item.png')
    },{
      id:'4',
      name:'14k 골드 반지',
      price:'1,200,000원',
      img:require('../../assets/images/item.png')
    },
    {
      id:'5',
      name:'14k 골드 반지',
      price:'1,200,000원',
      img:require('../../assets/images/item.png')
    }
  ]
  export default {
    name: "Necklace",
    data(){
      return{
        ITEM
      }
    }
  }
</script>