<template>
  <section id="section6">
    <div class="s6_container">
      <img src="../../assets/images/home/section6/s6_img.png">
      <img src="../../assets/images/home/section6/s6_img2.png">
    </div>
  </section>
</template>

<script>
  export default {
    name: "section6"
  }
</script>

<style scoped>

</style>